import React, { useEffect, useState, } from "react";
import { Form } from "antd";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getImportanceLocumTenensPageContentRequest,
  postImportanceLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const ImportanceLocumTenensPage = () => {

  const dispatch = useDispatch();
  const { importanceLocumTenensPageData, locumTenensPageStatus, locumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Possibilities Section Cards
  const [possibilitiesSectionFirstCardIconUrl, setPossibilitiesSectionFirstCardIconUrl] = useState();
  const [possibilitiesSectionFirstCardIconFile, setPossibilitiesSectionFirstCardIconFile] = useState();
  const [possibilitiesSectionSecondCardIconUrl, setPossibilitiesSectionSecondCardIconUrl] = useState();
  const [possibilitiesSectionSecondCardIconFile, setPossibilitiesSectionSecondCardIconFile] = useState();
  const [possibilitiesSectionThirdCardIconUrl, setPossibilitiesSectionThirdCardIconUrl] = useState();
  const [possibilitiesSectionThirdCardIconFile, setPossibilitiesSectionThirdCardIconFile] = useState();
  const [possibilitiesSectionForthCardIconUrl, setPossibilitiesSectionForthCardIconUrl] = useState();
  const [possibilitiesSectionForthCardIconFile, setPossibilitiesSectionForthCardIconFile] = useState();
  const [possibilitiesSectionFifthCardIconUrl, setPossibilitiesSectionFifthCardIconUrl] = useState();
  const [possibilitiesSectionFifthCardIconFile, setPossibilitiesSectionFifthCardIconFile] = useState();
  const [possibilitiesSectionSixCardIconUrl, setPossibilitiesSectionSixCardIconUrl] = useState();
  const [possibilitiesSectionSixCardIconFile, setPossibilitiesSectionSixCardIconFile] = useState();
  // how work Section
  const [workSectionFirstImageUrl, setWorkSectionFirstImageUrl] = useState();
  const [workSectionFirstImageFile, setWorkSectionFirstImageFile] = useState();
  const [workSectionSecondImageUrl, setWorkSectionSecondImageUrl] = useState();
  const [workSectionSecondImageFile, setWorkSectionSecondImageFile] = useState();
  // Faq Section
  const [faqSectionImageUrl, setFaqSectionImageUrl] = useState();
  const [faqSectionImageFile, setFaqSectionImageFile] = useState();
  // Who Work Locum Tenens Section Video
  const [ourPeopleSectionVideoUrl, setOurPeopleSectionVideoUrl] = useState();
  const [ourPeopleSectionVideoFile, setOurPeopleSectionVideoFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getImportanceLocumTenensPageContentRequest(CONTENT_PAGES.IMPORTANCE_LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (importanceLocumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[advantageSection][advantageHeading]": importanceLocumTenensPageData?.contentPage?.detail?.advantageSection?.advantageHeading,
        "detail[advantageSection][advantageDescription]": importanceLocumTenensPageData?.contentPage?.detail?.advantageSection?.advantageDescription,
        "detail[possibilitiesSection][possibilitiesHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.possibilitiesHeading,
        "detail[possibilitiesSection][possibilitiesDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.possibilitiesDescription,
        "detail[possibilitiesSection][firstCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.firstCardHeading,
        "detail[possibilitiesSection][firstCardDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.firstCardDescription,
        "detail[possibilitiesSection][secondCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.secondCardHeading,
        "detail[possibilitiesSection][secondCardDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.secondCardDescription,
        "detail[possibilitiesSection][thirdCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.thirdCardHeading,
        "detail[possibilitiesSection][thirdCardDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.thirdCardDescription,
        "detail[possibilitiesSection][forthCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.forthCardHeading,
        "detail[possibilitiesSection][forthCardDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.forthCardDescription,
        "detail[possibilitiesSection][fifthCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.fifthCardHeading,
        "detail[possibilitiesSection][fifthCardDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.fifthCardDescription,
        "detail[possibilitiesSection][sixCardHeading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.sixCardHeading,
        "detail[possibilitiesSection][sixCardDescription]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.sixCardDescription,
        "detail[possibilitiesSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.heading,
        "detail[possibilitiesSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.possibilitiesSection?.description,

        "detail[workSection][workHeading]": importanceLocumTenensPageData?.contentPage?.detail?.workSection?.workHeading,
        "detail[workSection][workDescription]": importanceLocumTenensPageData?.contentPage?.detail?.workSection?.workDescription,
        "detail[workSection][firstSubWorkHeading]": importanceLocumTenensPageData?.contentPage?.detail?.workSection?.firstSubWorkHeading,
        "detail[workSection][firstSubWorkDescription]": importanceLocumTenensPageData?.contentPage?.detail?.workSection?.firstSubWorkDescription,
        "detail[workSection][secondSubWorkHeading]": importanceLocumTenensPageData?.contentPage?.detail?.workSection?.secondSubWorkHeading,
        "detail[workSection][secondSubWorkDescription]": importanceLocumTenensPageData?.contentPage?.detail?.workSection?.secondSubWorkDescription,
        "detail[faqsSection][heading]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.heading,
        "detail[faqsSection][description]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.description,
        "detail[faqsSection][firstQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.firstQuestion,
        "detail[faqsSection][firstAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.firstAnswer,
        "detail[faqsSection][secondQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.secondQuestion,
        "detail[faqsSection][secondAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.secondAnswer,
        "detail[faqsSection][thirdQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.thirdQuestion,
        "detail[faqsSection][thirdAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.thirdAnswer,
        "detail[faqsSection][forthQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.forthQuestion,
        "detail[faqsSection][forthAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.forthAnswer,
        "detail[faqsSection][fiftyQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.fiftyQuestion,
        "detail[faqsSection][fiftyAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.fiftyAnswer,
        "detail[faqsSection][sixQuestion]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.sixQuestion,
        "detail[faqsSection][sixAnswer]": importanceLocumTenensPageData?.contentPage?.detail?.faqsSection?.sixAnswer,

        //Seo Values Set
        meta_title: importanceLocumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: importanceLocumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: importanceLocumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: importanceLocumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: importanceLocumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? null);
      setWorkSectionFirstImageUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.workSectionFirstImage ?? null);
      setWorkSectionSecondImageUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.workSectionSecondImage ?? null);
      setFaqSectionImageUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.faqSectionImage ?? null);
      setWorkSectionFirstImageUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.workSectionFirstImage ?? null);
      setOurPeopleSectionVideoUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.ourPeopleSectionVideo ?? null);
      setPossibilitiesSectionFirstCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionFirstCardIcon ?? BannerImage);
      setPossibilitiesSectionSecondCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionSecondCardIcon ?? BannerImage);
      setPossibilitiesSectionThirdCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionThirdCardIcon ?? BannerImage);
      setPossibilitiesSectionForthCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionForthCardIcon ?? BannerImage);
      setPossibilitiesSectionFifthCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionFifthCardIcon ?? BannerImage);
      setPossibilitiesSectionSixCardIconUrl(importanceLocumTenensPageData?.contentPage?.contentImages?.possibilitiesSectionSixCardIcon ?? BannerImage);
      setTwitterImageUrl(importanceLocumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(importanceLocumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage)
    }
  }, [importanceLocumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Importance Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[faqSectionImage]": faqSectionImageFile ?? "",
      "contentImages[workSectionFirstImage]": workSectionFirstImageFile ?? "",
      "contentImages[workSectionSecondImage]": workSectionSecondImageFile ?? "",
      "contentImages[ourPeopleSectionVideo]": ourPeopleSectionVideoFile ?? "",
      "contentImages[possibilitiesSectionFirstCardIcon]": possibilitiesSectionFirstCardIconFile ?? "",
      "contentImages[possibilitiesSectionSecondCardIcon]": possibilitiesSectionSecondCardIconFile ?? "",
      "contentImages[possibilitiesSectionThirdCardIcon]": possibilitiesSectionThirdCardIconFile ?? "",
      "contentImages[possibilitiesSectionForthCardIcon]": possibilitiesSectionForthCardIconFile ?? "",
      "contentImages[possibilitiesSectionFifthCardIcon]": possibilitiesSectionFifthCardIconFile ?? "",
      "contentImages[possibilitiesSectionSixCardIcon]": possibilitiesSectionSixCardIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postImportanceLocumTenensPageContentRequest({ data, id: importanceLocumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {locumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : locumTenensPageStatus === STATUSES.ERROR ? (
        <div>{locumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">Importance Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Advantage Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Advantage Section Heading"
                      name="detail[advantageSection][advantageHeading]"
                      placeText="Advantage Section Heading"
                    />
                    <TextAreas
                      labelText="Advantage Section Description"
                      name="detail[advantageSection][advantageDescription]"
                      placeText="Advantage Section Description"
                    />
                    {/* Possibilities Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Heading"
                      name="detail[possibilitiesSection][possibilitiesHeading]"
                      placeText="Possibilities Section Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Description"
                      name="detail[possibilitiesSection][possibilitiesDescription]"
                      placeText="Possibilities Section Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section First Card Icon"
                      imageUrl={possibilitiesSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionFirstCardIconFile}
                      setImageUrl={setPossibilitiesSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section First Card Heading"
                      name="detail[possibilitiesSection][firstCardHeading]"
                      placeText="Possibilities Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section First Card Description"
                      name="detail[possibilitiesSection][firstCardDescription]"
                      placeText="Possibilities Section First Card Description"
                    />

                    <BannerImageCard
                      title="Possibilities Section Second Card Icon"
                      imageUrl={possibilitiesSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionSecondCardIconFile}
                      setImageUrl={setPossibilitiesSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Second Card Heading"
                      name="detail[possibilitiesSection][secondCardHeading]"
                      placeText="Possibilities Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Second Card Description"
                      name="detail[possibilitiesSection][secondCardDescription]"
                      placeText="Possibilities Section Second Card Description"
                    />

                    <BannerImageCard
                      title="Possibilities Section Third Card Icon"
                      imageUrl={possibilitiesSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionThirdCardIconFile}
                      setImageUrl={setPossibilitiesSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Third Card Heading"
                      name="detail[possibilitiesSection][thirdCardHeading]"
                      placeText="Possibilities Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Third Card Description"
                      name="detail[possibilitiesSection][thirdCardDescription]"
                      placeText="Possibilities Section Third Card Description"
                    />


                    <BannerImageCard
                      title="Possibilities Section Forth Card Icon"
                      imageUrl={possibilitiesSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionForthCardIconFile}
                      setImageUrl={setPossibilitiesSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Forth Card Heading"
                      name="detail[possibilitiesSection][forthCardHeading]"
                      placeText="Possibilities Section Forth Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Forth Card Description"
                      name="detail[possibilitiesSection][forthCardDescription]"
                      placeText="Possibilities Section Forth Card Description"
                    />

                    <BannerImageCard
                      title="Possibilities Section Fifth Card Icon"
                      imageUrl={possibilitiesSectionFifthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionFifthCardIconFile}
                      setImageUrl={setPossibilitiesSectionFifthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Fifth Card Heading"
                      name="detail[possibilitiesSection][fifthCardHeading]"
                      placeText="Possibilities Section Fifth Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Fifth Card Description"
                      name="detail[possibilitiesSection][fifthCardDescription]"
                      placeText="Possibilities Section Fifth Card Description"
                    />
                    <BannerImageCard
                      title="Possibilities Section Six Card Icon"
                      imageUrl={possibilitiesSectionSixCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setPossibilitiesSectionSixCardIconFile}
                      setImageUrl={setPossibilitiesSectionSixCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Possibilities Section Six Card Heading"
                      name="detail[possibilitiesSection][sixCardHeading]"
                      placeText="Possibilities Section Six Card Heading"
                    />
                    <TextAreas
                      labelText="Possibilities Section Six Card Description"
                      name="detail[possibilitiesSection][sixCardDescription]"
                      placeText="Possibilities Section Six Card Description"
                    />
                    
                    {/* how work Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Heading"
                      name="detail[workSection][workHeading]"
                      placeText="Work Section Heading"
                    />
                    <TextAreas
                      labelText="Work Section Description"
                      name="detail[workSection][workDescription]"
                      placeText="Work Section Description"
                    />
                    <BannerImageCard
                      title="work Section First image"
                      imageUrl={workSectionFirstImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorkSectionFirstImageFile}
                      setImageUrl={setWorkSectionFirstImageUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section First Sub Heading"
                      name="detail[workSection][firstSubWorkHeading]"
                      placeText="Work Section First Sub Heading"
                    />
                    <TextAreas
                      labelText="Work Section First Sub Description"
                      name="detail[workSection][firstSubWorkDescription]"
                      placeText="Work Section First Sub Description"
                    />
                      <BannerImageCard
                      title="work Section Second image"
                      imageUrl={workSectionSecondImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorkSectionSecondImageFile}
                      setImageUrl={setWorkSectionSecondImageUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Second Sub Heading"
                      name="detail[workSection][secondSubWorkHeading]"
                      placeText="Work Section Second Sub Heading"
                    />
                    <TextAreas
                      labelText="Work Section Second Sub Description"
                      name="detail[workSection][secondSubWorkDescription]"
                      placeText="Work Section Second Sub Description"
                    />

                    {/* FAQS Section */}
                    <BannerImageCard
                      title="Faq Section Image"
                      imageUrl={faqSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setFaqSectionImageFile}
                      setImageUrl={setFaqSectionImageUrl}
                      imageText="Change image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Heading"
                      name="detail[faqsSection][heading]"
                      placeText="FAQS Section Heading"
                    />
                    <TextAreas
                      labelText="FAQS Section Description"
                      name="detail[faqsSection][description]"
                      placeText="FAQS Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section First Question"
                      name="detail[faqsSection][firstQuestion]"
                      placeText="FAQS Section First Question"
                    />
                    <TextAreas
                      labelText="FAQS Section First Answer"
                      name="detail[faqsSection][firstAnswer]"
                      placeText="FAQS Section First Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Second Question"
                      name="detail[faqsSection][secondQuestion]"
                      placeText="FAQS Section Second Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Second Answer"
                      name="detail[faqsSection][secondAnswer]"
                      placeText="FAQS Section Second Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Third Question"
                      name="detail[faqsSection][thirdQuestion]"
                      placeText="FAQS Section Third Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Third Answer"
                      name="detail[faqsSection][thirdAnswer]"
                      placeText="FAQS Section Third Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Forth Question"
                      name="detail[faqsSection][forthQuestion]"
                      placeText="FAQS Section Forth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Forth Answer"
                      name="detail[faqsSection][forthAnswer]"
                      placeText="FAQS Section Forth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Fifty Question"
                      name="detail[faqsSection][fiftyQuestion]"
                      placeText="FAQS Section Fifty Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Fifty Answer"
                      name="detail[faqsSection][fiftyAnswer]"
                      placeText="FAQS Section Fifty Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Six Question"
                      name="detail[faqsSection][sixQuestion]"
                      placeText="FAQS Section Six Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Six Answer"
                      name="detail[faqsSection][sixAnswer]"
                      placeText="FAQS Section Six Answer"
                    />

                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default ImportanceLocumTenensPage;
