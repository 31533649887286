import React from "react";
// VideoCard component
const VideoCard = ({
  width = 400,
  height = 300,
  videoUrl,
  setVideoUrl,
  setVideoFile,
}) => {
  const handleFileChange = (file) => {
    setVideoFile(file);
    setVideoUrl(URL.createObjectURL(file));
  };
  const inputRef = React.useRef();

  return (
    <div className="VideoCard">
      <label htmlFor="videoInput" className="VideoCard_label mb-2">
        Choose a video:
      </label>
      <input
        ref={inputRef}
        id="videoInput"
        className="VideoCard_input mb-4"
        type="file"
        onChange={(e) => handleFileChange(e.target.files[0])}
        accept=".mov,.mp4"
      />
      {videoUrl ? (
        <video
          className="VideoCard_video"
          width="100%"
          height={height}
          controls
          src={videoUrl}
        />
      ) : (
        <p className="VideoCard_message">No video selected</p>
      )}
    </div>
  );
};

export default VideoCard;
