import { MailOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Pagination, Row, Space, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import RangePickers from "../../components/DatePicker/RangePickers";
import { errorNotification } from "../../components/notifications/alertNotification";
import { STATUSES } from "../../config/constants/defaultValues";
import {
  getContactFormExportListRequest,
  getContactFormListRequest,
} from "../../redux/contactFormListSlice";
import { JobRequestStyle } from "../style";
import { SystemConfigurationWrapped } from "../systemConfiguration/style";

const ContactFormList = () => {
  const dispatch = useDispatch();
  const { status, contactFormData, error } = useSelector(
    (state) => state.contactFormList
  );
  const [search, setSearch] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [dateRange, setDateRange] = useState("");

  useEffect(() => {
    dispatch(getContactFormListRequest({}));
  }, [dispatch]);

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const paginationOnChange = (data, pageSize) => {
    dispatch(
      getContactFormListRequest({
        generalSearch: search,
        page: data,
        pageSize: pageSize,
        dateRange,
      })
    );
  };

  const onDateChange = ({ dateRange }) => {
    setDateRange(dateRange);
    dispatch(
      getContactFormListRequest({
        generalSearch: search,
        dateRange: dateRange,
      })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(
      getContactFormListRequest({
        generalSearch,
        dateRange: dateRange,
      })
    );
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const data = await dispatch(
        getContactFormExportListRequest({
          generalSearch: search,
          dateRange: dateRange,
        })
      );

      let filtered = [...data.payload];
      console.log("filtered", filtered);
      const worksheet = XLSX.utils.json_to_sheet(
        filtered.map((record) => ({
          id: record.id,
          "First Name": record.first_name,
          "Last Name": record.last_name,
          email: record.email,
          "Phone Number": record.phone,
          Discipline: record.discipline.name,
          Message: record.message,
          "Contact Date": record.updated_at,
        }))
      );

      const currentDateTimeString = moment(new Date()).format(
        "MM-DD-YYYY-hh:mm-A"
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `intuitive-Contact-DataSheet-${currentDateTimeString}.xlsx`
      );
    } catch (error) {
      errorNotification("Error while fetching or processing data:", error);
    } finally {
      setExportLoading(false); // Ensure that loading indicator is turned off, even in case of an error
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      width: 100,
      ellipsis: true,
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      width: 100,
      ellipsis: true,
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
      width: 190,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Discipline",
      dataIndex: "discipline",
      ellipsis: true,
      width: 190,
      render: (text) => <strong>{text?.name ?? ""}</strong>,
    },
    {
      title: "Message",
      dataIndex: "message",
      ellipsis: true,
      width: 190,
    },
    {
      title: "Contact Data",
      dataIndex: "updated_at",
      ellipsis: true,
      width: 120,
      // render: (text) => moment(text).format('MM-DD-YYYY'),
      sorter: (a, b) => {
        // Convert the date strings to Date objects for comparison
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);

        return dateA - dateB; // To sort in ascending order
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 100,
      render: (text, record) => (
        <Mailto email={record.email} subject="" body="">
          <Space>
            <MailOutlined />
            Replay
          </Space>
        </Mailto>
      ),
    },
  ];

  return (
    <>
      <JobRequestStyle>
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <div className="row pb-3 pb-sm-5">
                <div className="col-sm-9">
                  <div className="heading">Contact Form List</div>
                </div>
              </div>

              {/* <Form form={form}> */}
              <div className="row pb-4 pb-sm-5">
                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="discipline_id"
                      placeText="Select Discipline"
                      req={false}
                      labelText="Select Discipline"
                      notFoundContent={DisciplineStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ discipline: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ discipline: null });
                      }}
                      onClick={getDisciplineData}
                      options={DisciplineData?.collection_list ?? []}
                    />
                  </div> */}

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <RangePickers
                    name="Date_Posted"
                    req={false}
                    labelText="Date Range"
                    onChange={(_, dateStrings) => {
                      if (dateStrings[0] === "") {
                        onDateChange({ dateRange: null });
                      } else {
                        onDateChange({
                          dateRange: `${dateStrings[0]} , ${dateStrings[1]}`,
                        });
                      }
                    }}
                    on
                  />
                </div>
                {/* <div className="col-sm-6 text-end">
                    <Button className="reject_btn" onClick={clearSelect}>Clear All Filters</Button>
                  </div> */}
              </div>

              {/* </Form> */}
              {/* <JobListCard jobsList={contactFormData?.data} /> */}
              <SystemConfigurationWrapped>
                <div className="row pb-3">
                  <div className="col-sm-6 p-sm-0">
                    <Input
                      prefix={<SearchOutlined style={{ width: "20px" }} />}
                      allowClear
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (!e.target.value) {
                          onSearch("");
                          setSearch("");
                        }
                      }}
                      onPressEnter={() => onSearch(search)}
                      style={{
                        marginRight: "15px",
                      }}
                    />
                  </div>
                  <div className="col-sm-6 text-end mt-3 mt-sm-0">
                    <Button
                      className="reject_btn"
                      loading={exportLoading}
                      onClick={exportToExcel}
                    >
                      Export Excel
                    </Button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 p-sm-0">
                    {/* <div className="tab_section"> */}
                    {/* <div className="table-responsive"> */}
                    <Table
                      dataSource={contactFormData?.data ?? []}
                      columns={columns}
                      loading={status === STATUSES.LOADING}
                      bordered={true}
                      indentSize={33}
                      pagination={false} // Disable pagination
                      scroll={{ x: 1000 }}
                    />
                  </div>
                </div>
                {/* </div> */}
                {/* </div> */}
              </SystemConfigurationWrapped>
            </Col>
          </Row>
        </div>

        {
          <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
            <Pagination
              current={contactFormData?.meta.page ?? 1}
              pageSize={contactFormData?.meta.per_page ?? 10}
              total={contactFormData?.meta.total}
              showSizeChanger={true}
              onChange={paginationOnChange}
              onShowSizeChange={paginationOnChange}
            />
          </div>
        }
      </JobRequestStyle>
    </>
  );
};

export default ContactFormList;
