import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getWhatLocumTenensPageContentRequest,
  postWhatLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const WhatLocumTenensPage = () => {

  const dispatch = useDispatch();
  const { whatLocumTenensPageData, whatLocumTenensPageStatus, whatLocumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Difference Section 
  const [differenceSectionIconUrl, setDifferenceSectionIconUrl] = useState();
  const [differenceSectionIconFile, setDifferenceSectionIconFile] = useState();
    // benefit Section 
    const [benefitSectionIconUrl, setBenefitSectionIconUrl] = useState();
    const [benefitSectionIconFile, setBenefitSectionIconFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getWhatLocumTenensPageContentRequest(CONTENT_PAGES.WHAT_LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (whatLocumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": whatLocumTenensPageData?.contentPage?.detail?.bannerSection?.description,

        // Reasons Section
        "detail[workSection][text]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.text,
        "detail[workSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.heading,
        "detail[workSection][description]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.description,
        "detail[workSection][firstText]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.firstText,
        "detail[workSection][firstCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.firstCardHeading,
        "detail[workSection][firstCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.firstCardDescription,
        "detail[workSection][secondText]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.secondText,
        "detail[workSection][secondCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.secondCardHeading,
        "detail[workSection][secondCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.secondCardDescription,
        "detail[workSection][thirdText]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.thirdText,
        "detail[workSection][thirdCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.thirdCardHeading,
        "detail[workSection][thirdCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.workSection?.thirdCardDescription,
        // Testimonial Section
        "detail[testimonialsSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.testimonialsSection?.heading,
        // Difference Section
        "detail[differenceSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.heading,
        "detail[differenceSection][description]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.description,
        "detail[differenceSection][firstCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.firstCounterValue,
        "detail[differenceSection][firstCounterText]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.firstCounterText,
        "detail[differenceSection][secondCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.secondCounterValue,
        "detail[differenceSection][secondCounterText]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.secondCounterText,
        "detail[differenceSection][thirdCounterValue]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.thirdCounterValue,
        "detail[differenceSection][thirdCounterText]": whatLocumTenensPageData?.contentPage?.detail?.differenceSection?.thirdCounterText,
        // benefit Work
        "detail[benefitSection][heading]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.heading,
        "detail[benefitSection][description]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.description,
        "detail[benefitSection][firstCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.firstCardHeading,
        "detail[benefitSection][firstCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.firstCardDescription,
        "detail[benefitSection][secondCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.secondCardHeading,
        "detail[benefitSection][secondCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.secondCardDescription,
        "detail[benefitSection][thirdCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.thirdCardHeading,
        "detail[benefitSection][thirdCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.thirdCardDescription,
        "detail[benefitSection][forthCardHeading]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.forthCardHeading,
        "detail[benefitSection][forthCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.benefitSection?.forthCardDescription,
        // benefit Work
        "detail[flexibilitySection][heading]": whatLocumTenensPageData?.contentPage?.detail?.flexibilitySection?.heading,
        "detail[flexibilitySection][description]": whatLocumTenensPageData?.contentPage?.detail?.flexibilitySection?.description,
        "detail[flexibilitySection][firstCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.flexibilitySection?.firstCardDescription,
        "detail[flexibilitySection][secondCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.flexibilitySection?.secondCardDescription,
        "detail[flexibilitySection][thirdCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.flexibilitySection?.thirdCardDescription,
        "detail[flexibilitySection][forthCardDescription]": whatLocumTenensPageData?.contentPage?.detail?.flexibilitySection?.forthCardDescription,
        //Seo Values Set
        meta_title: whatLocumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: whatLocumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: whatLocumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: whatLocumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: whatLocumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: whatLocumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(whatLocumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setDifferenceSectionIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.differenceSectionIcon ?? BannerImage);
      setBenefitSectionIconUrl(whatLocumTenensPageData?.contentPage?.contentImages?.benefitSectionIcon ?? BannerImage);
      setTwitterImageUrl(whatLocumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(whatLocumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [whatLocumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'What Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[differenceSectionIcon]": differenceSectionIconFile ?? "",
      "contentImages[benefitSectionIcon]": benefitSectionIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postWhatLocumTenensPageContentRequest({ data, id: whatLocumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {whatLocumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : whatLocumTenensPageStatus === STATUSES.ERROR ? (
        <div>{whatLocumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >What Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />

                    {/* Reasons Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Heading"
                      name="detail[workSection][heading]"
                      placeText="Work Section Heading"
                    />
                    <TextAreas
                      labelText="Work Section Description"
                      name="detail[workSection][description]"
                      placeText="Work Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section First Text"
                      name="detail[workSection][firstText]"
                      placeText="Reasons Section First Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section First Card Heading"
                      name="detail[workSection][firstCardHeading]"
                      placeText="Work Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Work Section First Card Description"
                      name="detail[workSection][firstCardDescription]"
                      placeText="Work Section First Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Second Text"
                      name="detail[workSection][secondText]"
                      placeText="Work Section Second Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Second Card Heading"
                      name="detail[workSection][secondCardHeading]"
                      placeText="Work Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Work Section Second Card Description"
                      name="detail[workSection][secondCardDescription]"
                      placeText="Work Section Second Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Third Text"
                      name="detail[workSection][thirdText]"
                      placeText="Work Section Third Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Work Section Third Card Heading"
                      name="detail[workSection][thirdCardHeading]"
                      placeText="Work Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Work Section Third Card Description"
                      name="detail[workSection][thirdCardDescription]"
                      placeText="Work Section Third Card Description"
                    />

                    {/* Difference Section */}

                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section Heading"
                      name="detail[differenceSection][heading]"
                      placeText="Difference Section Heading"
                    />
                    <TextAreas
                      labelText="Difference Section Description"
                      name="detail[differenceSection][description]"
                      placeText="Difference Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section First Counter Value"
                      name="detail[differenceSection][firstCounterValue]"
                      placeText="Difference Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section First Counter Text"
                      name="detail[differenceSection][firstCounterText]"
                      placeText="Difference Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section Second Counter Value"
                      name="detail[differenceSection][secondCounterValue]"
                      placeText="Difference Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section Second Counter Text"
                      name="detail[differenceSection][secondCounterText]"
                      placeText="Difference Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section Third Counter Value"
                      name="detail[differenceSection][thirdCounterValue]"
                      placeText="Difference Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Difference Section Third Counter Text"
                      name="detail[differenceSection][thirdCounterText]"
                      placeText="Difference Section Third Counter Text"
                    />
                    <BannerImageCard
                      title="Difference Images Section"
                      imageUrl={differenceSectionIconUrl}
                      isBlogImage={true}
                      setImageFile={setDifferenceSectionIconFile}
                      setImageUrl={setDifferenceSectionIconUrl}
                      imageText="Change Image"
                    />
                    {/* Benefit Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefit Section Heading"
                      name="detail[benefitSection][heading]"
                      placeText="Benefit Section Heading"
                    />
                    <TextAreas
                      labelText="Benefit Section Description"
                      name="detail[benefitSection][description]"
                      placeText="Benefit Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefit Section First Card Heading"
                      name="detail[benefitSection][firstCardHeading]"
                      placeText="Benefit Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Benefit Section First Card Description"
                      name="detail[benefitSection][firstCardDescription]"
                      placeText="Benefit Section First Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefit Section Second Card Heading"
                      name="detail[benefitSection][secondCardHeading]"
                      placeText="Benefit Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Benefit Section Second Card Description"
                      name="detail[benefitSection][secondCardDescription]"
                      placeText="Benefit Section Second Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefit Section Third Card Heading"
                      name="detail[benefitSection][thirdCardHeading]"
                      placeText="Benefit Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Benefit Section Third Card Description"
                      name="detail[benefitSection][thirdCardDescription]"
                      placeText="Benefit Section Third Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefit Section Forth Card Heading"
                      name="detail[benefitSection][forthCardHeading]"
                      placeText="Benefit Section Forth Card Heading"
                    />
                    <TextAreas
                      labelText="Benefit Section Forth Card Description"
                      name="detail[benefitSection][forthCardDescription]"
                      placeText="Benefit Section Forth Card Description"
                    />
                   <BannerImageCard
                      title="Benefit Images Section"
                      imageUrl={benefitSectionIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitSectionIconFile}
                      setImageUrl={setBenefitSectionIconUrl}
                      imageText="Change Image"
                    />
                    {/* Flexibility Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Flexibility Section Heading"
                      name="detail[flexibilitySection][heading]"
                      placeText="Flexibility Section Heading"
                    />
                    <TextAreas
                      labelText="Flexibility Section Description"
                      name="detail[flexibilitySection][description]"
                      placeText="Flexibility Section Description"
                    />
                    <TextAreas
                      labelText="Flexibility Section First Card Description"
                      name="detail[flexibilitySection][firstCardDescription]"
                      placeText="Flexibility Section First Card Description"
                    />
                    <TextAreas
                      labelText="Flexibility Section Second Card Description"
                      name="detail[flexibilitySection][secondCardDescription]"
                      placeText="Flexibility Section Second Card Description"
                    />
                    <TextAreas
                      labelText="Flexibility Section Third Card Description"
                      name="detail[flexibilitySection][thirdCardDescription]"
                      placeText="Flexibility Section Third Card Description"
                    />
                    <TextAreas
                      labelText="Flexibility Section Forth Card Description"
                      name="detail[flexibilitySection][forthCardDescription]"
                      placeText="Flexibility Section Forth Card Description"
                    />
                     {/* Testimonial section */}
                    
                     <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form> 
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default WhatLocumTenensPage;
