import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { CONTACT_PAGE_API, CONTENT_PAGES_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";

// HomeContentPage Requests
export const getHomeContentPageContentRequest = createAsyncThunk(
    "contentManagement/getHomeContentPageRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postHomeContentPageContentRequest = createAsyncThunk(
    "contentManagement/postHomeContentPageRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// What Locum Tenens Page Requests
export const getWhatLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/getWhatLocumTenensPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postWhatLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/postWhatLocumTenensPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Why Work Locum Tenens Page Requests
export const getWhyWorkLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/getWhyWorkLocumTenensPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postWhyWorkLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/postWhyWorkLocumTenensPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Locum Tenens Page Requests
export const getLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/getLocumTenensPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/postLocumTenensPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Importance Locum Tenens Page Requests
export const getImportanceLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/getImportanceLocumTenensPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const postImportanceLocumTenensPageContentRequest = createAsyncThunk(
    "contentManagement/postImportanceLocumTenensPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

// Job List Page Requests
export const getJobListPageContentRequest = createAsyncThunk(
    "contentManagement/getJobListPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postJobListPageContentRequest = createAsyncThunk(
    "contentManagement/postJobListPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Login Page Requests
export const getLoginContentRequest = createAsyncThunk(
    "contentManagement/getLoginContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postLoginContentRequest = createAsyncThunk(
    "contentManagement/postLoginContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Register Page Requests
export const getRegisterContentRequest = createAsyncThunk(
    "contentManagement/getRegisterContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postRegisterContentRequest = createAsyncThunk(
    "contentManagement/postRegisterContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Forgot Password Page Requests
export const getForgotPasswordContentRequest = createAsyncThunk(
    "contentManagement/getForgotPasswordContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postForgotPasswordContentRequest = createAsyncThunk(
    "contentManagement/postForgotPasswordContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Reset Password Page Requests
export const getResetPasswordContentRequest = createAsyncThunk(
    "contentManagement/getResetPasswordContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postResetPasswordContentRequest = createAsyncThunk(
    "contentManagement/postResetPasswordContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Success Reset Password Page Requests
export const getSuccessResetPasswordContentRequest = createAsyncThunk(
    "contentManagement/getSuccessResetPasswordContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postSuccessResetPasswordContentRequest = createAsyncThunk(
    "contentManagement/postSuccessResetPasswordContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// ContactPage Requests
export const getContactPageContentRequest = createAsyncThunk(
    "contentManagement/getContactPageRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;

            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postContactPageContentRequest = createAsyncThunk(
    "contentManagement/getContactPageRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

// About Page Requests
export const getAboutPageContentRequest = createAsyncThunk(
    "contentManagement/getAboutPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postAboutPageContentRequest = createAsyncThunk(
    "contentManagement/postAboutPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Terms Page Requests
export const getTermsPageContentRequest = createAsyncThunk(
    "contentManagement/getTermsPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postTermsPageContentRequest = createAsyncThunk(
    "contentManagement/postTermsPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Privacy Page Requests
export const getPrivacyPageContentRequest = createAsyncThunk(
    "contentManagement/getPrivacyPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postPrivacyPageContentRequest = createAsyncThunk(
    "contentManagement/postPrivacyPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Cookie Policy Page Requests
export const getCookiePolicyPageContentRequest = createAsyncThunk(
    "contentManagement/getCookiePolicyPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postCookiePolicyPageContentRequest = createAsyncThunk(
    "contentManagement/postCookiePolicyPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Web Site Footer Requests
export const getWebSiteFooterContentRequest = createAsyncThunk(
    "contentManagement/getWebSiteFooterContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postWebSiteFooterContentRequest = createAsyncThunk(
    "contentManagement/postWebSiteFooterContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);


export const getBlogContentRequest = createAsyncThunk(
    "contentManagement/contentManagementData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_PAGE_API,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const contentManagementSlice = createSlice({
    name: "contentManagement",
    initialState: {
        homeContentPageData: null,
        homeContentPageStatus: STATUSES.IDLE,
        homeContentPageError: null,
        // ContactUs state
        whatLocumTenensPageData: null,
        whatLocumTenensPageStatus: STATUSES.IDLE,
        whatLocumTenensPageError: null,
        // Why Work Locum Tenens state
        whyWorkLocumTenensPageData: null,
        whyWorkLocumTenensPageStatus: STATUSES.IDLE,
        whyWorkLocumTenensPageError: null,
        // Locum Tenens Page state
        locumTenensPageData: null,
        locumPageStatus: STATUSES.IDLE,
        locumPageError: null,
        // ImportanceLocumTenensPage state
        importanceLocumTenensPageData: null,
        importanceLocumTenensPageStatus: STATUSES.IDLE,
        importanceLocumTenensPageError: null,
       
        // Job List state 
        jobListPageData: null,
        jobListPageStatus: STATUSES.IDLE,
        jobListPageError: null,
        // Login state 
        loginPageData: null,
        loginPageStatus: STATUSES.IDLE,
        loginPageError: null,
        // register state 
        registerPageData: null,
        registerPageStatus: STATUSES.IDLE,
        registerPageError: null,
        // Forgot Password state 
        forgotPasswordPageData: null,
        forgotPasswordPageStatus: STATUSES.IDLE,
        forgotPasswordPageError: null,
        // Reset Password state 
        resetPasswordPageData: null,
        resetPasswordPageStatus: STATUSES.IDLE,
        resetPasswordPageError: null,
        // Success Reset Password state 
        successResetPasswordPageData: null,
        successResetPasswordPageStatus: STATUSES.IDLE,
        successResetPasswordPageError: null,
        // ContactUs state
        contactPageData: null,
        contactPageStatus: STATUSES.IDLE,
        contactPageError: null,
        // AboutUs state
        aboutPageData: null,
        aboutPageStatus: STATUSES.IDLE,
        aboutPageError: null,
        // TermsPage state
        termsPageData: null,
        termsPageStatus: STATUSES.IDLE,
        termsPageError: null,
        // PrivacyPage state
        privacyPageData: null,
        privacyPageStatus: STATUSES.IDLE,
        privacyPageError: null,
        // Cookie Policy Page state
        cookiePolicyPageData: null,
        cookiePolicyPageStatus: STATUSES.IDLE,
        cookiePolicyPageError: null,
        // Web Site Footer state
        webSiteFooterData: null,
        webSiteFooterStatus: STATUSES.IDLE,
        webSiteFooterError: null,
    },
    extraReducers: {

        // HomeContent
        [getHomeContentPageContentRequest.pending]: (state, action) => {
            state.homeContentPageStatus = STATUSES.LOADING;
        },
        [getHomeContentPageContentRequest.fulfilled]: (state, action) => {

            state.homeContentPageStatus = STATUSES.IDLE;
            state.homeContentPageData = action.payload;
        },
        [getHomeContentPageContentRequest.rejected]: (state, action) => {
            state.homeContentPageStatus = STATUSES.ERROR;
            state.homeContentPageError = action.payload;
        },
        [postHomeContentPageContentRequest.pending]: (state, action) => {
            state.homeContentPageStatus = STATUSES.LOADING;
        },
        [postHomeContentPageContentRequest.fulfilled]: (state, action) => {

            state.homeContentPageStatus = STATUSES.IDLE;
            state.homeContentPageData = action.payload;
        },
        [postHomeContentPageContentRequest.rejected]: (state, action) => {
            state.homeContentPageStatus = STATUSES.ERROR;
            state.homeContentPageError = action.payload;
        },
        // What Locum Tenens Page Content
        [getWhatLocumTenensPageContentRequest.pending]: (state, action) => {
            state.whatLocumTenensPageStatus = STATUSES.LOADING;
        },
        [getWhatLocumTenensPageContentRequest.fulfilled]: (state, action) => {
            state.whatLocumTenensPageStatus = STATUSES.IDLE;
            state.whatLocumTenensPageData = action.payload;
        },
        [getWhatLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.whatLocumTenensPageStatus = STATUSES.ERROR;
            state.whatLocumTenensPageError = action.payload;
        },
        [postWhatLocumTenensPageContentRequest.pending]: (state, action) => {
            state.whatLocumTenensPageStatus = STATUSES.LOADING;
        },
        [postWhatLocumTenensPageContentRequest.fulfilled]: (state, action) => {
            state.whatLocumTenensPageStatus = STATUSES.IDLE;
            state.whatLocumTenensPageData = action.payload;
        },
        [postWhatLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.whatLocumTenensPageStatus = STATUSES.ERROR;
            state.whatLocumTenensPageError = action.payload;
        },
        // Why Work Locum Tenens Page Content
        [getWhyWorkLocumTenensPageContentRequest.pending]: (state, action) => {
            state.whyWorkLocumTenensPageStatus = STATUSES.LOADING;
        },
        [getWhyWorkLocumTenensPageContentRequest.fulfilled]: (state, action) => {
            state.whyWorkLocumTenensPageStatus = STATUSES.IDLE;
            state.whyWorkLocumTenensPageData = action.payload;
        },
        [getWhyWorkLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.whyWorkLocumTenensPageStatus = STATUSES.ERROR;
            state.whyWorkLocumTenensPageError = action.payload;
        },
        [postWhyWorkLocumTenensPageContentRequest.pending]: (state, action) => {
            state.whyWorkLocumTenensPageStatus = STATUSES.LOADING;
        },
        [postWhyWorkLocumTenensPageContentRequest.fulfilled]: (state, action) => {
            state.whyWorkLocumTenensPageStatus = STATUSES.IDLE;
            state.whyWorkLocumTenensPageData = action.payload;
        },
        [postWhyWorkLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.whyWorkLocumTenensPageStatus = STATUSES.ERROR;
            state.whyWorkLocumTenensPageError = action.payload;
        },
        // Importance Locum Tenens Page Content
        [getImportanceLocumTenensPageContentRequest.pending]: (state, action) => {
            state.importanceLocumTenensPageStatus = STATUSES.LOADING;
        },
        [getImportanceLocumTenensPageContentRequest.fulfilled]: (state, action) => {
            state.importanceLocumTenensPageStatus = STATUSES.IDLE;
            state.importanceLocumTenensPageData = action.payload;
        },
        [getImportanceLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.importanceLocumTenensPageStatus = STATUSES.ERROR;
            state.importanceLocumTenensPageError = action.payload;
        },
        [postImportanceLocumTenensPageContentRequest.pending]: (state, action) => {
            state.importanceLocumTenensPageStatus = STATUSES.LOADING;
        },
        [postImportanceLocumTenensPageContentRequest.fulfilled]: (state, action) => {
            state.importanceLocumTenensPageStatus = STATUSES.IDLE;
            state.importanceLocumTenensPageData = action.payload;
        },
        [postImportanceLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.importanceLocumTenensPageStatus = STATUSES.ERROR;
            state.importanceLocumTenensPageError = action.payload;
        },
     
        // Locum Tenens Page Content
        [getLocumTenensPageContentRequest.pending]: (state, action) => {
            state.locumTenensPageStatus = STATUSES.LOADING;
        },
        [getLocumTenensPageContentRequest.fulfilled]: (state, action) => {

            state.locumTenensPageStatus = STATUSES.IDLE;
            state.locumTenensPageData = action.payload;
        },
        [getLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.locumTenensPageStatus = STATUSES.ERROR;
            state.locumTenensPageError = action.payload;
        },
        [postLocumTenensPageContentRequest.pending]: (state, action) => {
            state.locumTenensPageStatus = STATUSES.LOADING;
        },
        [postLocumTenensPageContentRequest.fulfilled]: (state, action) => {

            state.locumTenensPageStatus = STATUSES.IDLE;
            state.locumTenensPageData = action.payload;
        },
        [postLocumTenensPageContentRequest.rejected]: (state, action) => {
            state.locumTenensPageStatus = STATUSES.ERROR;
            state.locumTenensPageError = action.payload;
        },
        // Job List Content
        [getJobListPageContentRequest.pending]: (state, action) => {
            state.jobListPageStatus = STATUSES.LOADING;
        },
        [getJobListPageContentRequest.fulfilled]: (state, action) => {

            state.jobListPageStatus = STATUSES.IDLE;
            state.jobListPageData = action.payload;
        },
        [getJobListPageContentRequest.rejected]: (state, action) => {
            state.jobListPageStatus = STATUSES.ERROR;
            state.jobListPageError = action.payload;
        },
        [postJobListPageContentRequest.pending]: (state, action) => {
            state.jobListPageStatus = STATUSES.LOADING;
        },
        [postJobListPageContentRequest.fulfilled]: (state, action) => {

            state.jobListPageStatus = STATUSES.IDLE;
            state.jobListPageData = action.payload;
        },
        [postJobListPageContentRequest.rejected]: (state, action) => {
            state.jobListPageStatus = STATUSES.ERROR;
            state.jobListPageError = action.payload;
        },
        // Login Content
        [getLoginContentRequest.pending]: (state, action) => {
            state.loginPageStatus = STATUSES.LOADING;
        },
        [getLoginContentRequest.fulfilled]: (state, action) => {

            state.loginPageStatus = STATUSES.IDLE;
            state.loginPageData = action.payload;
        },
        [getLoginContentRequest.rejected]: (state, action) => {
            state.loginPageStatus = STATUSES.ERROR;
            state.loginPageError = action.payload;
        },
        [postLoginContentRequest.pending]: (state, action) => {
            state.loginPageStatus = STATUSES.LOADING;
        },
        [postLoginContentRequest.fulfilled]: (state, action) => {

            state.loginPageStatus = STATUSES.IDLE;
            state.loginPageData = action.payload;
        },
        [postLoginContentRequest.rejected]: (state, action) => {
            state.loginPageStatus = STATUSES.ERROR;
            state.loginPageError = action.payload;
        },
        // Register Content
        [getRegisterContentRequest.pending]: (state, action) => {
            state.registerPageStatus = STATUSES.LOADING;
        },
        [getRegisterContentRequest.fulfilled]: (state, action) => {

            state.registerPageStatus = STATUSES.IDLE;
            state.registerPageData = action.payload;
        },
        [getRegisterContentRequest.rejected]: (state, action) => {
            state.registerPageStatus = STATUSES.ERROR;
            state.registerPageError = action.payload;
        },
        [postRegisterContentRequest.pending]: (state, action) => {
            state.registerPageStatus = STATUSES.LOADING;
        },
        [postRegisterContentRequest.fulfilled]: (state, action) => {

            state.registerPageStatus = STATUSES.IDLE;
            state.registerPageData = action.payload;
        },
        [postRegisterContentRequest.rejected]: (state, action) => {
            state.registerPageStatus = STATUSES.ERROR;
            state.registerPageError = action.payload;
        },
        // Forgot Password Content
        [getForgotPasswordContentRequest.pending]: (state, action) => {
            state.forgotPasswordPageStatus = STATUSES.LOADING;
        },
        [getForgotPasswordContentRequest.fulfilled]: (state, action) => {

            state.forgotPasswordPageStatus = STATUSES.IDLE;
            state.forgotPasswordPageData = action.payload;
        },
        [getForgotPasswordContentRequest.rejected]: (state, action) => {
            state.forgotPasswordPageStatus = STATUSES.ERROR;
            state.forgotPasswordPageError = action.payload;
        },
        [postForgotPasswordContentRequest.pending]: (state, action) => {
            state.forgotPasswordPageStatus = STATUSES.LOADING;
        },
        [postForgotPasswordContentRequest.fulfilled]: (state, action) => {

            state.forgotPasswordPageStatus = STATUSES.IDLE;
            state.forgotPasswordPageData = action.payload;
        },
        [postForgotPasswordContentRequest.rejected]: (state, action) => {
            state.forgotPasswordPageStatus = STATUSES.ERROR;
            state.forgotPasswordPageError = action.payload;
        },
        // Reset Password Content
        [getResetPasswordContentRequest.pending]: (state, action) => {
            state.resetPasswordPageStatus = STATUSES.LOADING;
        },
        [getResetPasswordContentRequest.fulfilled]: (state, action) => {

            state.resetPasswordPageStatus = STATUSES.IDLE;
            state.resetPasswordPageData = action.payload;
        },
        [getResetPasswordContentRequest.rejected]: (state, action) => {
            state.resetPasswordPageStatus = STATUSES.ERROR;
            state.resetPasswordPageError = action.payload;
        },
        [postResetPasswordContentRequest.pending]: (state, action) => {
            state.resetPasswordPageStatus = STATUSES.LOADING;
        },
        [postResetPasswordContentRequest.fulfilled]: (state, action) => {

            state.resetPasswordPageStatus = STATUSES.IDLE;
            state.resetPasswordPageData = action.payload;
        },
        [postResetPasswordContentRequest.rejected]: (state, action) => {
            state.resetPasswordPageStatus = STATUSES.ERROR;
            state.resetPasswordPageError = action.payload;
        },
        // Success Reset Password Content
        [getSuccessResetPasswordContentRequest.pending]: (state, action) => {
            state.successResetPasswordPageStatus = STATUSES.LOADING;
        },
        [getSuccessResetPasswordContentRequest.fulfilled]: (state, action) => {

            state.successResetPasswordPageStatus = STATUSES.IDLE;
            state.successResetPasswordPageData = action.payload;
        },
        [getSuccessResetPasswordContentRequest.rejected]: (state, action) => {
            state.successResetPasswordPageStatus = STATUSES.ERROR;
            state.successResetPasswordPageError = action.payload;
        },
        [postSuccessResetPasswordContentRequest.pending]: (state, action) => {
            state.successResetPasswordPageStatus = STATUSES.LOADING;
        },
        [postSuccessResetPasswordContentRequest.fulfilled]: (state, action) => {

            state.successResetPasswordPageStatus = STATUSES.IDLE;
            state.successResetPasswordPageData = action.payload;
        },
        [postSuccessResetPasswordContentRequest.rejected]: (state, action) => {
            state.successResetPasswordPageStatus = STATUSES.ERROR;
            state.successResetPasswordPageError = action.payload;
        },
        // ContactPage
        [getContactPageContentRequest.pending]: (state, action) => {
            state.contactPageStatus = STATUSES.LOADING;
        },
        [getContactPageContentRequest.fulfilled]: (state, action) => {

            state.contactPageStatus = STATUSES.IDLE;
            state.contactPageData = action.payload;
        },
        [getContactPageContentRequest.rejected]: (state, action) => {
            state.contactPageStatus = STATUSES.ERROR;
            state.contactPageError = action.payload;
        },
        [postContactPageContentRequest.pending]: (state, action) => {
            state.contactPageStatus = STATUSES.LOADING;
        },
        [postContactPageContentRequest.fulfilled]: (state, action) => {

            state.contactPageStatus = STATUSES.IDLE;
            state.contactPageData = action.payload;
        },
        [postContactPageContentRequest.rejected]: (state, action) => {
            state.contactPageStatus = STATUSES.ERROR;
            state.contactPageError = action.payload;
        },
        // About Page Content
        [getAboutPageContentRequest.pending]: (state, action) => {
            state.aboutPageStatus = STATUSES.LOADING;
        },
        [getAboutPageContentRequest.fulfilled]: (state, action) => {

            state.aboutPageStatus = STATUSES.IDLE;
            state.aboutPageData = action.payload;
        },
        [getAboutPageContentRequest.rejected]: (state, action) => {
            state.aboutPageStatus = STATUSES.ERROR;
            state.aboutPageError = action.payload;
        },
        [postAboutPageContentRequest.pending]: (state, action) => {
            state.aboutPageStatus = STATUSES.LOADING;
        },
        [postAboutPageContentRequest.fulfilled]: (state, action) => {

            state.aboutPageStatus = STATUSES.IDLE;
            state.aboutPageData = action.payload;
        },
        [postAboutPageContentRequest.rejected]: (state, action) => {
            state.aboutPageStatus = STATUSES.ERROR;
            state.aboutPageError = action.payload;
        },
        // Terms Page
        [getTermsPageContentRequest.pending]: (state, action) => {
            state.termsPageStatus = STATUSES.LOADING;
        },
        [getTermsPageContentRequest.fulfilled]: (state, action) => {
            state.termsPageStatus = STATUSES.IDLE;
            state.termsPageData = action.payload;
        },
        [getTermsPageContentRequest.rejected]: (state, action) => {
            state.termsPageStatus = STATUSES.ERROR;
            state.termsPageError = action.payload;
        },
        [postTermsPageContentRequest.pending]: (state, action) => {
            state.termsPageStatus = STATUSES.LOADING;
        },
        [postTermsPageContentRequest.fulfilled]: (state, action) => {

            state.termsPageStatus = STATUSES.IDLE;
            state.termsPageData = action.payload;
        },
        [postTermsPageContentRequest.rejected]: (state, action) => {
            state.termsPageStatus = STATUSES.ERROR;
            state.termsPageError = action.payload;
        },
        // Privacy Page
        [getPrivacyPageContentRequest.pending]: (state, action) => {
            state.privacyPageStatus = STATUSES.LOADING;
        },
        [getPrivacyPageContentRequest.fulfilled]: (state, action) => {
            state.privacyPageStatus = STATUSES.IDLE;
            state.privacyPageData = action.payload;
        },
        [getPrivacyPageContentRequest.rejected]: (state, action) => {
            state.privacyPageStatus = STATUSES.ERROR;
            state.privacyPageError = action.payload;
        },
        [postPrivacyPageContentRequest.pending]: (state, action) => {
            state.privacyPageStatus = STATUSES.LOADING;
        },
        [postPrivacyPageContentRequest.fulfilled]: (state, action) => {
            state.privacyPageStatus = STATUSES.IDLE;
            state.privacyPageData = action.payload;
        },
        [postPrivacyPageContentRequest.rejected]: (state, action) => {
            state.privacyPageStatus = STATUSES.ERROR;
            state.privacyPageError = action.payload;
        },
        // cookiePolicy
        [getCookiePolicyPageContentRequest.pending]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.LOADING;
        },
        [getCookiePolicyPageContentRequest.fulfilled]: (state, action) => {

            state.cookiePolicyPageStatus = STATUSES.IDLE;
            state.cookiePolicyPageData = action.payload;
        },
        [getCookiePolicyPageContentRequest.rejected]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.ERROR;
            state.cookiePolicyPageError = action.payload;
        },
        [postCookiePolicyPageContentRequest.pending]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.LOADING;
        },
        [postCookiePolicyPageContentRequest.fulfilled]: (state, action) => {

            state.cookiePolicyPageStatus = STATUSES.IDLE;
            state.cookiePolicyPageData = action.payload;
        },
        [postCookiePolicyPageContentRequest.rejected]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.ERROR;
            state.cookiePolicyPageError = action.payload;
        },
        // Web Site Footer
        [getWebSiteFooterContentRequest.pending]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.LOADING;
        },
        [getWebSiteFooterContentRequest.fulfilled]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.IDLE;
            state.webSiteFooterData = action.payload;
        },
        [getWebSiteFooterContentRequest.rejected]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.ERROR;
            state.webSiteFooterError = action.payload;
        },
        [postWebSiteFooterContentRequest.pending]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.LOADING;
        },
        [postWebSiteFooterContentRequest.fulfilled]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.IDLE;
            state.webSiteFooterData = action.payload;
        },
        [postWebSiteFooterContentRequest.rejected]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.ERROR;
            state.webSiteFooterError = action.payload;
        },
    },
});
export default contentManagementSlice.reducer;

